/*font-family: 'Noto Sans JP', sans-serif;*/
@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Black.eot');
  src: url('../fonts/Nunito-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-Black.woff2') format('woff2'),
      url('../fonts/Nunito-Black.woff') format('woff'),
      url('../fonts/Nunito-Black.ttf') format('truetype'),
      url('../fonts/Nunito-Black.svg#Nunito-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Bold.eot');
  src: url('../fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-Bold.woff2') format('woff2'),
      url('../fonts/Nunito-Bold.woff') format('woff'),
      url('../fonts/Nunito-Bold.ttf') format('truetype'),
      url('../fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Light.eot');
  src: url('../fonts/Nunito-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-Light.woff2') format('woff2'),
      url('../fonts/Nunito-Light.woff') format('woff'),
      url('../fonts/Nunito-Light.ttf') format('truetype'),
      url('../fonts/Nunito-Light.svg#Nunito-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-ExtraLight.eot');
  src: url('../fonts/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
      url('../fonts/Nunito-ExtraLight.woff') format('woff'),
      url('../fonts/Nunito-ExtraLight.ttf') format('truetype'),
      url('../fonts/Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Regular.eot');
  src: url('../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-Regular.woff2') format('woff2'),
      url('../fonts/Nunito-Regular.woff') format('woff'),
      url('../fonts/Nunito-Regular.ttf') format('truetype'),
      url('../fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-SemiBold.eot');
  src: url('../fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
      url('../fonts/Nunito-SemiBold.woff') format('woff'),
      url('../fonts/Nunito-SemiBold.ttf') format('truetype'),
      url('../fonts/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root{
  --oops-navyblue: #112459;
  --oops-darkgray: #212121;
  --oops-lightgray: #666666;
  --oops-lightBlue: #edf2ff;
}
.badge {font-weight: 500 !important;padding: 5px 8px !important;border-radius: .25rem !important;}
.badge.badge-default {background-color: #B0BEC5}
.badge.badge-primary {background-color: #2196F3}
.badge.badge-secondary {background-color: #323a45}
.badge.badge-success {background-color: #64DD17}
.badge.badge-warning {background-color: #FFD600}
.badge.badge-info {background-color: #29B6F6}
.badge.badge-danger {background-color: #ef1c1c}
body:not(.layout-fixed) .main-sidebar {height: 100vh !important;}
header,footer {width: 100%;padding: 20px;}
header {align-self: flex-start;}
footer {align-self: flex-end;}
.copyRights{text-align: center; font-size:13px;font-weight: 500;color: var(--bs-white);}
.header-container {display: flex;align-items: center;justify-content: space-around;}
.oops-lang-dropdown {width: 100%;text-align: right;}
.oops-lang-dropdown select {font-size: 14px;background: var(--bs-white);padding: 4px 10px;border: 1px solid var(--bs-white);border-radius: 5px;}
.oops-lang-dropdown select:focus{outline: none;}
.main-body {min-height: 100vh !important;height: 100% !important; display: flex;font-family: 'Noto Sans JP', sans-serif;font-weight: 400; font-size: 1rem; color: #111;}
.main-wrapper{width: 100%;background-color: var(--oops-navyblue);box-sizing: border-box;display: flex; align-items: center;background-attachment: fixed;padding: 0;flex-wrap: wrap;}
.oopsWrapper {width: 100%;}
.oopsFormContainer {width: 100%;box-sizing: border-box;max-width: 500px;margin: 0 auto;padding: 30px;background-color: rgba(255, 255, 255, 1);border-radius: 5px;}
.radio-toggles {align-items: center;background: var(--oops-lightBlue);border: 0 solid #003928;border-radius: 999px;display: flex;justify-content: center;margin:0;max-width: 220px;overflow: hidden;padding: 5px;position: relative;}
.radio-toggles label {cursor: pointer;padding: 5px;text-align: center;width: 50%;z-index: 2;margin-bottom: 0;font-size: 12px;}
.radio-toggles .slide-item {background: var(--oops-navyblue);border-radius: 999px;height: calc(100% - 6px);left: calc(50% + 0px);position: absolute;width: calc(50% - 3px);transition: left 0.1s;z-index: 0;}
.radio-toggles input[type="radio"] {left: -9999px; position: absolute;z-index: -1;}
.radio-toggles input[type="radio"]:nth-of-type(1):checked ~ .slide-item {left: 3px;}
.radio-toggles input[type="radio"]:checked + label {color: var(--bs-white);}
.registerFormBlock{padding: 0;border: 0 solid #003928;border-radius: 0;margin-top: 30px;}
.form-group {margin-bottom: 10px;}
.form-group .label {font-weight: 400 !important;font-size: 13px;line-height: normal;margin-bottom: 3px;}
.form-group .form-control,.form-group select,.form-group .PhoneInputInput{background-color: rgba(255, 255, 255, 0);color: var(--oops-darkgray);font-weight: 500;display: block;width: 100%;padding: .375rem .55rem;font-size: .85rem;font-weight: 400;line-height: 1.5;background-clip: padding-box;border: 1px solid #ced4da;-webkit-appearance: none;appearance: none;border-radius: .25rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;min-height: 38px;}
.css-tlfecz-indicatorContainer,.css-1gtu0rj-indicatorContainer{padding: 0 !important;}
.css-1okebmr-indicatorSeparator,.css-109onse-indicatorSeparator{width:0 !important;}
.css-14el2xx-placeholder, .countrytitle, .css-qc6sy-singleValue{font-size: .9rem !important;}
.css-319lph-ValueContainer{padding-right: 0 !important;}
/* Hide Arrows From Input Number Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
/* Hide Arrows From Input Number Firefox */
input[type=number] {-moz-appearance: textfield;}
.appearance-auto{-webkit-appearance: auto !important;appearance: auto !important;}
.css-1s2u09g-control{border-radius: .25rem !important;border: 1px solid #ced4da !important;}
.PhoneInput {display: flex;align-items: center;}
.PhoneInputCountry {position: relative;align-self: stretch;display: flex;align-items: center;margin-right: 0.35em;}
.PhoneInputCountrySelect {position: absolute;top: 0;left: 0;height: 100%;width: 100%;z-index: 1;border: 0;opacity: 0;cursor: pointer;}
.PhoneInputCountryIconImg {display: block;width: 100%;height: 100%;}
.PhoneInputCountryIcon--border {background-color: rgba(0,0,0,0.1);box-shadow: 0 0 0 1px rgb(0 0 0 / 50%), inset 0 0 0 1px rgb(0 0 0 / 50%);width: calc(1em * 1.5);height: 1em;}
.PhoneInputCountrySelectArrow {display: block;content: '';width: 0.3em;height: 0.3em;margin-left: 0.35em;border-style: solid;border-color: inherit;border-top-width: 0;border-bottom-width: 1px;border-left-width: 0;border-right-width: 1px;-webkit-transform: rotate(45deg);transform: rotate(45deg);opacity: 0.45;}
.form-group .form-control:focus{box-shadow: rgb(38, 132, 255) 0px 0px 0px 1px;border-color:  rgb(38, 132, 255);}
.PhoneInput--focus .PhoneInputInput:focus{outline:none !important;border-color:  rgb(38, 132, 255);box-shadow: rgb(38, 132, 255) 0px 0px 0px 1px;}
.form-group .helper-text {margin-top: 4px !important;display: block;font-size: 12px;line-height: normal;font-weight: 600;}
.registerFormBlock h6 {font-weight: 600;font-size: 15px;}
.link-text {font-size: 14px;text-decoration: none;color: var(--oops-lightgray);}
#country .css-qc6sy-singleValue {display: flex;align-items: center;}
#country .css-qc6sy-singleValue img {margin-right: 5px;position: relative;top: -1px;}
.btn{font-size: .9rem !important;}
.badge-list-block {margin-bottom: 10px;margin-top: 10px;display: flex;flex-wrap: wrap;}
.badge-list-block .badge {margin-right: 2px;margin-bottom: 2px;display: flex;align-items: center;justify-content: space-between;}
.badge-list-block .badge .badge-close{margin-left: 5px;display: flex;line-height: normal;cursor: pointer;}
.badge-list-block .badge i {font-size: 16px;color: #212529;}
.skills-group {display: flex;align-items: flex-end;margin-left: -10px;margin-right: -10px;}
.skills-group .form-group {width: 40%;padding-left: 10px;padding-right: 10px;}
.skills-group .form-group:last-child {width: 20%;}
.skill-add-btn-block .btn {min-height: 38px;}
.countrytitle {display: flex;align-items: center;justify-content: space-between;width: 100%;}
.flagIcon {width: 20px;}
.mobileInputGroup {display: flex;}
.mobileDialCode {width: 60px;}
.ml-2{margin-left:10px;}
.signInLinkAction-block {display: flex;justify-content: flex-end;margin-bottom: 10px;}
.signInLinkAction-block .link-text {font-size: 12px;font-weight: 500;text-decoration: underline;}
.DiallistBlock{display: flex;}
.cstmInputGroup {flex-wrap: nowrap !important;}